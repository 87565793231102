import { useState, useEffect } from 'react';
import { getResults } from '../../api';


const initialEditableRowData = {
  FullName: '',
  FirstName: '',
  LastName: '',
  MiddleName: '',
  SSN: '',
  DateOfBirth: '',
  DateOfHire: '',
  DateOfReHire: '',
  DateOfTermination: '',
  Status: 'Active',
  GrossCompensation: '',
  HoursWorked: '',
  DeferALS: '',
  ParticipantRoth: '',
  CompanyMatch: '',
  CompanyProfitSharing: '',
  AnnualLoanPayment: '',
  RollOver: ''
};

const splitDataByEndDate = (data, censusYear) => {
  const mainTableData = { Row: {} };
  const secondaryTableData = { Row: {} };

  const currentYear = parseInt(censusYear.current_year);

  Object.entries(data.Row).forEach(([key, employee]) => {
    const endDate = employee.DateOfTermination?.FinalValue?.Value || '';
    const endYear = endDate ? new Date(endDate).getFullYear() : null;
    
    // Check if the employee is marked as "NewHire"
    const isNewHire = employee.PriorYearEmployee?.FinalValue?.Message === "NewHire";
    
    // New check for duplicate key suffix
    const isDuplicate = key.endsWith('_duplicate');
    if (isNewHire || 
        (endYear !== null && endYear !== '-' && endYear < currentYear) || 
        isDuplicate) {
      secondaryTableData.Row[key] = employee;
    } else {
      mainTableData.Row[key] = employee;
    }
  });

  return { mainTableData, secondaryTableData };
};


const useResultsData = (currentProject, useLocalResults, currentView, setIsLoading, iFinanceId, censusYearData, customerNumber) => {
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [reportStartDates, setReportStartDates] = useState([]);
  const [reportEndDates, setReportEndDates] = useState([]);
  const [sources, setSources] = useState([]);
  const [currentYearErrors, setCurrentYearErrors] = useState(0);
  const [currentYearWarnings, setCurrentYearWarnings] = useState(0);
  const [previousYearErrors, setPreviousYearErrors] = useState(0);
  const [previousYearWarnings, setPreviousYearWarnings] = useState(0);

  const countErrorsAndWarnings = (tableData) => {
    let errorsCount = 0;
    let warningsCount = 0;
  
    Object.values(tableData.Row).forEach(row => {
      let hasError = false;
      let hasWarning = false;
  
      Object.values(row).forEach(field => {
        if (field?.FinalValue?.Status === "Error") {
          hasError = true;
        }
        if (field?.FinalValue?.Status === "Warning") {
          hasWarning = true;
        }
      });
  
      if (hasError) errorsCount++;
      if (hasWarning) warningsCount++;
    });
  
    return { errorsCount, warningsCount };
  };
  

  const fetchResults = async () => {
    setIsLoading(true);
    if (!currentProject) {
      setIsLoading(false);
      return;
    }
    try {
      const response = useLocalResults
        ? await fetch('/results.json').then(res => res.json())
        : await getResults(
            currentProject,
            iFinanceId,
            customerNumber,
            censusYearData.current_year,
            censusYearData.current_year_start_date,
            censusYearData.current_year_end_date,
            censusYearData.prior_year_start_date,
            censusYearData.prior_year_end_date,
          );
      if (response) {
        const results = response;
        const { mainTableData, secondaryTableData } = splitDataByEndDate(results, censusYearData);
        
        setData(mainTableData);
        setOldData(secondaryTableData);
  
        const { errorsCount: currentErrors, warningsCount: currentWarnings } = countErrorsAndWarnings(mainTableData);
        const { errorsCount: previousErrors, warningsCount: previousWarnings } = countErrorsAndWarnings(secondaryTableData);
        
        setCurrentYearErrors(currentErrors);
        setCurrentYearWarnings(currentWarnings);
        setPreviousYearErrors(previousErrors);
        setPreviousYearWarnings(previousWarnings);
  
        setReportEndDates(results.ReportPeriodEndDate || []);
        setReportStartDates(results.ReportPeriodStartDate || []);
        // Extract unique sources
        const startDateSources = results.ReportPeriodStartDate?.map(item => item.Source).filter(Boolean) || [];
        const endDateSources = results.ReportPeriodEndDate?.map(item => item.Source).filter(Boolean) || [];
        const uniqueSources = Array.from(new Set([...startDateSources, ...endDateSources]));
        setSources(uniqueSources);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, oldData, reportStartDates, reportEndDates, initialEditableRowData, setData, setOldData, sources, censusYearData, fetchResults, currentYearErrors, currentYearWarnings, previousYearErrors, previousYearWarnings };
};

export default useResultsData;
