import React, { useState, useEffect } from 'react';
import './HoursWorkedModal.css';
import closeIcon from './../../assets/close-icon.png';
import infoIcon from './../../assets/orange-info-icon.png';
import warningIcon from './../../assets/warning-icon.png';

const HoursWorkedModal = ({
  show,
  onClose,
  onSave,
  employeeFullName,
  hoursFinalValue,
  hoursWorkedNoteFinalValue,
  hoursIsOwner,
}) => {
  const [hoursWorked, setHoursWorked] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    if (show) {
      // Convert "0.00" to "0"
      const initialHoursWorked = hoursFinalValue === '0.00' ? '0' : hoursFinalValue || '';
      setHoursWorked(initialHoursWorked);
      setDescription(hoursWorkedNoteFinalValue || '');
      setError(false);
    }
  }, [show, hoursFinalValue, hoursWorkedNoteFinalValue]);

  if (!show) {
    return null;
  }

  const handleSubmit = () => {
    if (hoursWorked === '0' && description.trim() === '') {
      setError(true);
      return;
    }
    onSave(employeeFullName, hoursWorked, description);
    onClose();
  };

  const isExplainZeroHoursRed = hoursWorked === '0' && description.trim() === '';

  // Determine the description below "Hours Worked" based on the conditions
  let descriptionText = '';

  if (!hoursIsOwner) {
    // If the user is not an owner (is an employee)
    if (hoursWorked === '' || parseFloat(hoursWorked) <= 8760) {
      descriptionText =
        'Hours worked is required for most employees. Please explain why this employee has zero recorded hours.';
    } else if (parseFloat(hoursWorked) > 8760) {
      descriptionText =
        'The hours worked cannot exceed 8,760 hours in a year. Please review and correct the entered value.';
    }
  } else {
    // For owners, always display this message
    descriptionText = (
      <>
        To meet the Bona Fide Employee requirement, work at least 500 hours/year, paid or unpaid.{' '}
        <span className="tooltip-container">
          More information here.
          <div className="tooltip-content">
            <b>Exclusive Benefit Rule / Bona Fide Employee</b>
            <br />
            The person who rolls their retirement funds into the ROBS structure must
            be a bona fide employee of the business they've funded. The rule is
            somewhat vague, but to be safe, Guidant suggests the owner work at least
            500 hours per year.
          </div>
        </span>
      </>
    );
  }

  return (
    <div className="hwm-modal-overlay">
      <div className="hwm-modal-content">
        <div className="hwm-modal-header">
          <h2>Review Hours Worked</h2>
          <img
            src={closeIcon}
            alt="Close"
            className="hwm-modal-close-icon"
            onClick={onClose}
          />
        </div>
        <hr className="hwm-horizontal-rule" />

        {hoursWorked === '0' && (
          <>
            <div className="hwm-banner">
              <img src={infoIcon} alt="Info" className="hwm-banner-icon" />
              <span>
                Please update the {hoursIsOwner ? 'owner' : 'employee'} information below.
              </span>
            </div>
            <hr className="hwm-horizontal-rule" />
          </>
        )}

        <div className="hwm-modal-section">
          <label className="hwm-label">Hours Worked</label>
          <div className="hwm-input-container">
            <input
              type="number"
              className="hwm-input"
              value={hoursWorked}
              onChange={(e) => {
                setHoursWorked(e.target.value);
                // Reset error if hoursWorked is no longer '0'
                if (e.target.value !== '0') {
                  setError(false);
                }
              }}
            />
            <img src={warningIcon} alt="Warning" className="hwm-warning-icon" />
            {descriptionText && <p className="hwm-description">{descriptionText}</p>}
          </div>
        </div>

        {hoursWorked === '0' && (
          <div className="hwm-modal-section">
            <label className="hwm-label">Explain zero hours</label>
            <div className="hwm-input-container">
              <textarea
                className={`hwm-textarea ${isExplainZeroHoursRed ? 'hwm-textarea-error' : ''}`}
                rows="2"
                placeholder="Describe here."
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setError(false); // Clear error on input change
                }}
              ></textarea>
              <p
                className={`hwm-description ${
                  isExplainZeroHoursRed ? 'hwm-description-error' : ''
                }`}
              >
                Please explain why {hoursIsOwner ? 'owner' : 'employee'} has zero hours.
              </p>
            </div>
          </div>
        )}

        <button className="hwm-apply-button" onClick={handleSubmit}>
          SAVE
        </button>
      </div>
    </div>
  );
};

export default HoursWorkedModal;
