import React from 'react';
import AlertTag from './AlertTag';
import './AlertsList.css';  // Assuming you have this CSS file linked

const AlertsList = ({ errors, warnings, alertFilters, handleAlertClick }) => {
  // Only show the alerts if there's at least one error or warning
  const showErrors = errors > 0;
  const showWarnings = warnings > 0;
  const showReviewFor = showErrors || showWarnings;

  if (!showReviewFor) {
    return null;
  }

  return (
    <div className="alerts-wrapper">
      {showReviewFor && <span className="review-label">Review for:</span>}
      <div className="alerts-container">
        {showErrors && (
          <AlertTag
            label="Errors"
            count={errors}
            onClick={() => handleAlertClick('Error')}
            active={alertFilters.includes('Error')}
          />
        )}
        {showWarnings && (
          <AlertTag
            label="Warnings"
            count={warnings}
            onClick={() => handleAlertClick('Warning')}
            active={alertFilters.includes('Warning')}
          />
        )}
      </div>
    </div>
  );
};

export default AlertsList;
