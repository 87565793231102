import React, { useState } from 'react';
import './UploadFile.css';
import uploadFilesIcon from '../../assets/upload-files-icon.png';
import downloadTemplateIcon from '../../assets/download-template-icon.png';
import lightbulbIcon from '../../assets/lightbulb-icon.png'; // Import the lightbulb icon

function UploadFile({ onFileSelect }) {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const droppedFiles = event.dataTransfer.files;
    handleFiles(droppedFiles);
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    handleFiles(selectedFiles);
  };

  const handleFiles = (fileList) => {
    const filesArray = Array.from(fileList);
    onFileSelect(filesArray);
  };

  const downloadTemplate = () => {
    const link = document.createElement('a');
    link.href = 'https://guidantassets.blob.core.windows.net/public/census-template.csv';
    link.download = 'census-template.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openSupportedReports = () => {
    window.open('https://support.guidantfinancial.com/supported-payroll-providers/', '_blank');
};

  return (
    <div className="upload-file-container">
      <div className="upload-options">
        <div className="option">
          <div className="option-title">Option 1: Use our Template</div>
          <div className="download-template" onClick={downloadTemplate}>
            <img src={downloadTemplateIcon} alt="Download Template Icon" className="download-icon" />
            <span>DOWNLOAD TEMPLATE</span>
          </div>
        </div>

        <div className="option">
            <div className="option-title">Option 2: Upload Supported Reports from ADP or Paychex</div>
            <div className="learn-about-reports" onClick={openSupportedReports}>
                <img src={lightbulbIcon} alt="Lightbulb Icon" className="lightbulb-icon" />
                <span>LEARN ABOUT SUPPORTED REPORTS</span>
            </div>
        </div>
      </div>

      <div
        className={`file-upload-area ${dragOver ? 'drag-over' : ''}`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <img src={uploadFilesIcon} alt="Upload Files Icon" className="upload-icon" />
        <div className="upload-text">
          Drag and drop files here or <span className="browse-link">browse</span>
        </div>
        <div className="file-types">
          Reports supported: ADP or Paychex<br />
          Files supported: .PDF, .CSV, .XLS, .XLSX<br />
          Max size: 50 MB
        </div>
        <input
          type="file"
          accept=".pdf,.docx,.doc,.txt,.csv,.xlsx,.zip"
          multiple
          onChange={handleFileChange}
          className="file-input"
        />
      </div>
    </div>
  );
}

export default UploadFile;
