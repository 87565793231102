const BASE_URL = window.location.hostname === 'localhost' ? 'https://localhost:5001/api' : '/api';

const getAuthHeader = () => {
  const token = localStorage.getItem('id_token');
  return token ? { 'Authorization': `Bearer ${token}` } : {};
};

const handle401 = (response) => {
  if (response.status === 401) {
    // Token is expired or invalid, refresh the page
    window.location.reload();
  }
  return response;
};


export const getCustomerNumber = async (iFinanceId) => {
  try{
    const response = await fetch (`${BASE_URL}/project/get-customer-number`, {
      method: 'POST',
      headers: {
        'Content-Type' : 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body : JSON.stringify({iFinanceId})
    });

    handle401(response);

    if(!response.ok){
      throw new Error(`Error: ${response.statusText}`);
    } 

    const data = await response.json();
    return (data);
  }catch(error){
    console.log(error);
    throw error;
  }
}

// Get Project id given the customerNumber. If Project does not exist, endpoint creates one and returns the id.
export const getProject = async (customerNumber, iFinanceId) => {
  try {
    const response = await fetch(`${BASE_URL}/project/get-project`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body: JSON.stringify({ customerNumber, iFinanceId })
    });

    handle401(response);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return { id: data.id, censusYearData: data.censusYearData };
  } catch (error) {
    console.error('Error getting project:', error);
    throw error;
  }
};


export const uploadFiles = async (formData) => {
  try {
    const response = await fetch(`${BASE_URL}/project/upload-files`, {
      method: 'POST',
      headers: {
        ...getAuthHeader()
      },
      credentials: 'include',
      body: formData
    });

    handle401(response);

    const jsonResponse = await response.json();

    if (!response.ok && !jsonResponse.keywordResults) {
      throw new Error(jsonResponse.error || 'Error uploading files.');
    }

    return jsonResponse;
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const getProcessingStatus = async (projectId) => {
  try {
    const response = await fetch(`${BASE_URL}/project/get-processing-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body: JSON.stringify({ projectId })
    });

    handle401(response);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error getting processing status:', error);
    throw error;
  }
};

export const submitFinalData = async (customerNumber, planYear, planYearEndDate, planYearStartDate, priorYearStartDate, priorYearEndDate, iFinanceId, data, projectId) => {
  try {
    const response = await fetch(`${BASE_URL}/project/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body: JSON.stringify({ customerNumber, planYear, planYearEndDate, planYearStartDate, priorYearStartDate, priorYearEndDate, iFinanceId, data, projectId})
    });

    handle401(response);

    
    const responseData = await response.json();
    if (!responseData.success) {
      if (responseData.errors) {
        console.warn('Validation errors:', responseData.errors);
      }
    }

    return responseData;  // Ensure to return the response data
  } catch (error) {
    console.error('Error submitting final data:', error);
    throw error;
  }
};

export const saveData = async (projectId, data) => {
  try {
    const response = await fetch(`${BASE_URL}/project/save-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body: JSON.stringify({ ProjectId: projectId, data: data })
    });

    handle401(response);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error: ${response.statusText} - ${errorText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error saving data:', error);
    throw error;
  }
};

export const getResults = async (projectId, iFinanceId, customerNumber, planYear, planYearStartDate, planYearEndDate, priorYearStartDate, priorYearEndDate) => {
  try {
    const response = await fetch(`${BASE_URL}/project/get-results`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body: JSON.stringify({ projectId, iFinanceId, customerNumber, planYear, planYearStartDate, planYearEndDate, priorYearStartDate, priorYearEndDate})
    });

    handle401(response);

    if (response.status === 204) return null;

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const jsonResponse = await response.json();

    // Check if jsonResponse is null and handle it gracefully
    if (jsonResponse === null) {
      console.log('No results found for the given projectId');
      return null;
    }

    return jsonResponse;
  } catch (error) {
    console.error('Error getting results:', error);
    throw error;
  }
};

export const archiveProject = async (projectId) => {
  try {
    const response = await fetch(`${BASE_URL}/project/archive-project`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getAuthHeader()
      },
      credentials: 'include',
      body: JSON.stringify({ projectId })
    });

    handle401(response);

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const jsonResponse = await response.json();
    return jsonResponse.message === "Project archived successfully.";
  } catch (error) {
    console.error('Error archiving project:', error);
    return false;
  }
};
