import React, { useState, useEffect } from 'react';
import AlertsList from './AlertsList';
import Toolbar from './Toolbar';
import ResultsTable from './ResultsTable';
import previousYearIcon from './../../assets/red-dropdown-icon.png';
import currentYearIcon from './../../assets/blue-dropdown-icon.png';
import redInfoIcon from './../../assets/red-info-icon.png';  // Import the icon

const ResultsTableSection = ({
  title,
  data,
  currentPage,
  rowsPerPage,
  isAddingNewRow,
  editableRowIndex,
  editableRowData,
  handlePageChange,
  handleEditRow,
  handleSaveRow,
  handleInputChange,
  handleResolveClick,
  handleBulkResolveClick,
  handleZeroHourClick,
  initialEditableRowData,
  setIsAddingNewRow,
  setEditableRowIndex,
  setEditableRowData,
  setCurrentView,
  isOldData,
  showTable,
  setShowTable,
  activeTab,
  setActiveTab,
  searchTerm,
  setSearchTerm,
  handleAddRow,
  alertFilters,
  handleAlertClick,
  setData,
  setOldData,
  handleDeleteRowClick,
  errors,
  warnings
}) => {
  
  const [conflictColumns, setConflictColumns] = useState([]);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);  // State to handle tooltip visibility

  useEffect(() => {
    const columnsWithConflicts = [];
    const fieldSourcesMap = {};
  
    Object.entries(data.Row || {}).forEach(([employeeKey, employee]) => {
      Object.entries(employee).forEach(([field, value]) => {
        if (value?.FinalValue?.Value === 'conflict') {
          // Initialize a set to collect unique sources for this field if not already initialized
          if (!fieldSourcesMap[field]) {
            fieldSourcesMap[field] = new Set();
          }
          // Collect unique sources from the Values array
          value.Values.forEach(val => {
            fieldSourcesMap[field].add(val.Source);
          });
        }
      });
    });
  
    // Now, for each field in fieldSourcesMap, check if there are multiple unique sources
    Object.entries(fieldSourcesMap).forEach(([field, sourcesSet]) => {
      if (sourcesSet.size > 1) {
        columnsWithConflicts.push(field);
      }
    });
  
    setConflictColumns(columnsWithConflicts);
  }, [data]);
  

  const getFilteredData = (data, activeTab, isOldData) => {
    if (isOldData) {
      return data;
    }
    if (activeTab === 'viewAll') {
      return data;
    } else if (activeTab === 'previousYear') {
      return {
        Row: Object.fromEntries(
          Object.entries(data.Row).filter(
            ([key, employee]) => employee.PriorYearEmployee?.FinalValue?.Value === 'true'
          )
        )
      };
    } else if (activeTab === 'newEmployees') {
      return {
        Row: Object.fromEntries(
          Object.entries(data.Row).filter(
            ([key, employee]) => employee.PriorYearEmployee?.FinalValue?.Value !== 'true'
          )
        )
      };
    }
  };

  const filteredData = getFilteredData(data, activeTab, isOldData);

  return (
    <div className="dropdown-section">
      <div className="dropdown-header" onClick={() => setShowTable(!showTable)}>
        <img
          src={isOldData ? previousYearIcon : currentYearIcon}
          alt="Year Icon"
          className={`dropdown-icon ${showTable ? '' : 'collapsed'}`}
        />
        <span className="dropdown-title" style={{ color: isOldData ? '#D43030' : '#3067D4' }}>
          {title}
          {isOldData && (
            <span
              className="past-census-info-icon-wrapper"
              onMouseEnter={() => setIsTooltipVisible(true)}
              onMouseLeave={() => setIsTooltipVisible(false)}
            >
              <img
                src={redInfoIcon}
                alt="Info Icon"
                style={{ width: '16px', height: '16px', marginLeft: '8px', verticalAlign: 'middle' }}
              />
              {isTooltipVisible && (
                <div className="past-census-info-tooltip">
                  This section represents changes or new information entered that applies to the previous plan year.
                </div>
              )}
            </span>
          )}
        </span>
      </div>
      {showTable && (
        <>
          <AlertsList data={filteredData} alertFilters={alertFilters} handleAlertClick={handleAlertClick} errors={errors} warnings={warnings} />
          <Toolbar
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            handleAddRow={handleAddRow}
            setCurrentView={setCurrentView}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isOldData={isOldData}
          />
          <ResultsTable
            data={filteredData}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            isAddingNewRow={isAddingNewRow}
            editableRowIndex={editableRowIndex}
            editableRowData={editableRowData}
            handlePageChange={handlePageChange}
            handleEditRow={handleEditRow}
            handleSaveRow={handleSaveRow}
            handleInputChange={handleInputChange}
            handleResolveClick={handleResolveClick}
            handleBulkResolveClick={handleBulkResolveClick}
            handleZeroHourClick={handleZeroHourClick}
            setIsAddingNewRow={setIsAddingNewRow}
            setEditableRowIndex={setEditableRowIndex}
            initialEditableRowData={initialEditableRowData}
            setEditableRowData={setEditableRowData}
            setCurrentView={setCurrentView}
            isOldData={isOldData}
            handleAddRow={handleAddRow}
            alertFilters={alertFilters}
            handleDeleteRowClick={handleDeleteRowClick}
            conflictColumns={conflictColumns}
          />
        </>
      )}
    </div>
  );
  
};

export default ResultsTableSection;
